import { Injectable } from '@angular/core';
import { AppConstants } from '../app-constants';
import { Headers, Http, RequestOptions, Response } from '@angular/http';
import { map } from 'rxjs/operators';
import * as HttpStatus from 'http-status-codes';
import { BaseService } from './base.service'

@Injectable({
  providedIn: 'root'
})
export class AdminService extends BaseService {

  MASTER_ADMIN_USER_TYPES = [
      {
        'id': 0,
        'name': 'Master'
      },
      {
        'id': 1,
        'name': 'Administrador'
      },
      {
        'id': 2,
        'name': 'Comun'
      },
      {
        'id': 3,
        'name': 'Oficial'
      },
    ];

  ADMIN_ADMIN_USER_TYPES = [
      {
        'id': 1,
        'name': 'Administrador'
      },
      {
        'id': 2,
        'name': 'Comun'
      },
      {
        'id': 3,
        'name': 'Oficial'
      },
    ];

  ADMIN_TYPES = {
    0: this.MASTER_ADMIN_USER_TYPES,
    1: this.ADMIN_ADMIN_USER_TYPES
  }

  constructor(private http: Http) {
    super();
    this.endpoint = AppConstants.ADMIN_USER;
  }

  getLoggedUser() {
    const localStored = localStorage.getItem('adminUser');
    return JSON.parse(localStored);
  }

  login(adminUser: any) {
    return this.http.post(this.serverEndPlus('auth'), adminUser,
      {headers: this.headers})
      .pipe(map(
        (response: Response) => {
          const data = response.json();
          localStorage.setItem('adminUser', JSON.stringify(data));
          return response;
        }
      ));
  }

  resetPassword(adminUser: any) {
    return this.http.post(this.serverEndPlus('reset'), adminUser,
      {headers: this.headers})
      .pipe(map(
        (response: Response) => {
          return response;
        }
      ));
  }

  requestReset(adminUser: any) {
    return this.http.post(this.serverEndPlus('token'), adminUser,
      {headers: this.headers})
      .pipe(map(
        (response: Response) => {
          return response;
        }
      ));
  }

  validateToken(token: any) {
    return this.http.get(this.serverEndPlus('token/' + token),
      {headers: this.headers})
      .pipe(map(
        (response: Response) => {
          return response
        }
      ));
  }

  create(adminUser: any) {
    return this.http.post(this.serverEndpoint(), adminUser,
      {headers: this.headers})
      .pipe(map(
        (response: Response) => {
          return response.json();
        }
      ));
  }

  fetchOne(id: number) {
    return this.http.get(this.serverEndPlus(id))
      .pipe(map(
        (response: Response) => {
          return response.json();
        }
      ));
  }

  fetch(query: any) {
    return this.http.get(this.serverEndpoint(),
      { params: query})
      .pipe(map(
        (response: Response) => {
          return (response.status ===  HttpStatus.NO_CONTENT)
            ? [] : response.json();
        }
      ));
  }

  fetchTypes() {
    return this.http.get(this.serverEndPlus('types'))
      .pipe(map(
        (response: Response) => {
          return (response.status ===  HttpStatus.NO_CONTENT)
            ? [] : response.json();
        }
      ));
  }

  update(adminUser: any) {
    return this.http.put(this.serverEndpoint(), adminUser,
      {headers: this.headers})
      .pipe(map(
        (response: Response) => {
          return response.json();
        }
      ));
  }

  delete(user: any) {
    return this.http.delete(this.serverEndPlus(user), user)
      .pipe(map(
        (response: Response) => {
          return response.json();
        }
      ));
  }

}
