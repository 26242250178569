import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { ToastrModule } from 'ngx-toastr';
import { NgxChartsModule } from '@swimlane/ngx-charts';

import { AngularFireModule } from '@angular/fire';
import {AngularFireStorageModule, StorageBucket} from '@angular/fire/storage';

import { AppRoutingModule } from './app.routing';

import { NavbarModule } from './shared/navbar/navbar.module';
import { FooterModule } from './shared/footer/footer.module';
import { SidebarModule } from './sidebar/sidebar.module';
import { AppComponent } from './app.component';
import { PapaParseModule } from 'ngx-papaparse';
import { ConfirmationPopoverModule } from 'angular-confirmation-popover';
import { AuthService } from './service/auth.service';
import { AuthGuardService } from './service/auth-guard.service';
import { NgSelectModule } from '@ng-select/ng-select';
import { BootstrapModalModule } from 'ng2-bootstrap-modal';

import { environment } from '../environments/environment';

@NgModule({
  imports: [
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    HttpModule,
    NgxDatatableModule,
    HttpClientModule,
    NavbarModule,
    FooterModule,
    SidebarModule,
    ToastrModule.forRoot(),
    AppRoutingModule,
    NgxChartsModule,
    PapaParseModule,
    ConfirmationPopoverModule.forRoot({
      confirmButtonType: 'danger' // set defaults here
    }),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireStorageModule,
    NgSelectModule,
    BootstrapModalModule.forRoot({
      container: document.body
    }),
  ],
  declarations: [
    AppComponent,
  ],
  providers: [
    AuthService ,
    AuthGuardService,
  ],
  bootstrap: [ AppComponent ]
})
export class AppModule { }
