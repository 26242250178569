import { Injectable } from '@angular/core';
import { AppConstants } from '../app-constants';
import { Headers, Http, RequestOptions, Response } from '@angular/http';
import { map } from 'rxjs/operators';
import * as HttpStatus from 'http-status-codes';
import { environment } from 'environments/environment';

export class BaseService {

  selectedObject: any;

  endpoint: string;
  serverUrl: string;

  headers = new Headers({'Content-Type': 'application/json'});

  constructor() {
    this.serverUrl = environment.server_url;
  }

  serverEndpoint() {
    return `${this.serverUrl }/${this.endpoint}`;
  }

  serverEndPlus(param: any) {
    return `${this.serverUrl }/${this.endpoint}/${param}`;
  }

  serverPlusList(lst: any[]) {
    const params = lst.join('/');
    return `${this.serverUrl }/${this.endpoint}/${params}`;
  }

}
