// Class used to define the url to be used in requests

export class AppConstants {

  public static USER = 'user'
  public static ADMIN_USER = 'adminUser';
  public static VISIT_REGISTER = 'visitRegister';
  public static ADMIN_LOG = 'adminLog';
  public static COMPANY = 'company';
  public static COMPANY_LOCATION = 'companyLocation';
  public static LOCATION_POSITION = 'companyLocationPosition';
  public static POSITION_VISIT_TYPE = 'positionVisitType';
  public static POSITION_DESTINY = 'positionDestiny';

  public static USER_GROUP = 'userGroup';
  public static ADMIN_GROUP = 'adminUserGroup';

  public static USER_COMPANY = 'userCompany';
  public static ADMIN_COMPANY = 'adminUserCompany';

  public static ADMIN_POSITION = 'adminUserPosition';
  public static USER_POSITION = 'userPosition';

}
