import { Component, OnInit } from '@angular/core';
import { AdminService } from 'app/service/admin.service';

declare const $: any;
declare interface RouteInfo {
  path: string;
  title: string;
  icon: string;
}
export const MASTER_ROUTES: RouteInfo[] = [
  {
    path: '/dashboard/home',
    title: 'Inicio',
    icon: 'pe-7s-home'
  },
  {
    path: '/dashboard/user',
    title: 'Usuarios',
    icon: 'pe-7s-user'
  },
  {
    path: '/dashboard/admin',
    title: 'Usuarios Admin',
    icon: 'pe-7s-door-lock'
  },
  {
    path: '/dashboard/company',
    title: 'Compañías',
    icon: 'pe-7s-users'
  },
  {
    path: '/dashboard/complete',
    title: 'Completar registro',
    icon: 'pe-7s-compass'
  },
  {
    path: '/dashboard/registers',
    title: 'Consultar Registros',
    icon: 'pe-7s-graph3'
  },
  {
    path: '/dashboard/analytics',
    title: 'Analytics',
    icon: 'pe-7s-graph'
  },
];

export const ADMIN_ROUTES: RouteInfo[] = [
  {
    path: '/dashboard/home',
    title: 'Inicio',
    icon: 'pe-7s-home'
  },
  {
    path: '/dashboard/user',
    title: 'Usuarios',
    icon: 'pe-7s-user'
  },
  {
    path: '/dashboard/admin',
    title: 'Usuarios Admin',
    icon: 'pe-7s-door-lock'
  },
  {
    path: '/dashboard/complete',
    title: 'Completar registro',
    icon: 'pe-7s-compass'
  },
  {
    path: '/dashboard/registers',
    title: 'Consultar Registros',
    icon: 'pe-7s-graph3'
  },
  {
    path: '/dashboard/analytics',
    title: 'Analytics',
    icon: 'pe-7s-graph'
  },
];


export const ROUTES: RouteInfo[] = [
  {
    path: '/dashboard/home',
    title: 'Inicio',
    icon: 'pe-7s-home'
  },
  {
    path: '/dashboard/registers',
    title: 'Consultar Registros',
    icon: 'pe-7s-graph3'
  },
  {
    path: '/dashboard/analytics',
    title: 'Analytics',
    icon: 'pe-7s-graph'
  },
];

export const OFFICER_ROUTES: RouteInfo[] = [
  {
    path: '/dashboard/home',
    title: 'Inicio',
    icon: 'pe-7s-home'
  },
  {
    path: '/dashboard/complete',
    title: 'Completar registro',
    icon: 'pe-7s-compass'
  },
];



@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html'
})
export class SidebarComponent implements OnInit {

  menuItems: any[] = [];

  constructor( private adminService: AdminService) { }

  ngOnInit() {
    const loggedUser =  this.adminService.getLoggedUser();
    if (loggedUser.typeId ===  0) {
      this.menuItems = MASTER_ROUTES.filter(menuItem => menuItem);
    } else if (loggedUser.typeId ===  1) {
      this.menuItems = ADMIN_ROUTES.filter(menuItem => menuItem);
    } else if (loggedUser.typeId === 2 ) {
      this.menuItems = ROUTES.filter(menuItem => menuItem);
    } else if (loggedUser.typeId === 3) {
      this.menuItems = OFFICER_ROUTES.filter(menuItem => menuItem);
    }

  }

  isMobileMenu() {
    return ($(window).width() > 991) ? false : true ;
  }

}
